<template>
  <el-container style="height: 100vh">
    <el-aside width="200px">
      <div style="text-align: center; font-size: 18px; padding: 10px 0">
        <img src="../assets/crcgas_logo.png" />
      </div>
      <el-menu
        :default-active="active"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        router
        active-text-color="#ffd04b"
      >
        <el-menu-item
          v-for="(item, inx) in menuList"
          :index="'/system/' + item.path"
          link
          :key="inx"
        >
          <i :class="item.meta.icon"></i>
          <span>{{ item.meta.title }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div style="width: 100%" align="right">
          您好，{{ username }}
          <el-link
            :underline="false"
            type="primary"
            style="margin-left: 10px; cursor: pointer"
            @click="quitLogin"
            >退出</el-link
          >
        </div>
      </el-header>
      <el-main>
        <el-card shadow="never">
          <router-view></router-view>
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getPlatformType } from "../utils";
import { routes } from "../router";
export default {
  data() {
    return {
      active: "/system/NewsList",
      username: "",
      menuList: [],
    };
  },
  mounted() {
    this.username = localStorage.getItem("realname");
    // 检测平台类型是否有值，没有情况不让进
    const platform = getPlatformType();
    if (!platform) {
      this.$router.push("/login");
      this.$message.warning("登录超时，请重新登录");
    }
    this.menuList = routes.filter(
      (item) => item.meta.platform.indexOf(platform) > -1
    );
  },
  watch: {
    $route: {
      handler(val) {
        this.active = val.path;
      },
      immediate: true,
    },
  },
  methods: {
    quitLogin() {
      this.$confirm("确认退出当前账号？", "操作提示").then(() => {
        localStorage.clear();
        this.$router.push("/Login");
      });
    },
  },
};
</script>

<style>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #545c64;
  color: #333;
  /* text-align: center; */
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  padding: 10px;
}
.el-menu {
  border: 0;
}
</style>
